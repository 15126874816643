import './App.css';
import HomePage from './pages/HomePage';
import DownloadPage from './pages/DownloadPage';
import { Route, Switch, HashRouter } from 'react-router-dom';

function App() {
  return <div>
    <HashRouter>
        <Switch>
          <Route path='/play' exact component={DownloadPage} />
          <Route path='/' component={HomePage} />
        </Switch>
    </HashRouter>
  </div>
}

export default App;

/*   
git add .
git commit --allow-empty -m "Trigger rebuild"
git push
  
npm run deploy
-----------------------  
"build": "react-scripts build && cp build/index.html build/404.html",
"homepage": "https://kovlez.github.io/site/",
--------------------------
https://create-react-app.dev/docs/deployment/#step-1-add-homepage-to-packagejson -- Savior

asdasd

*/